@use '../variables'
@use '../mixins'

.banner-container
  background-image: url('../../img/ocean2.jpg')
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  overflow: hidden
  height: 15rem
  display: flex
  justify-content: center
  width: 100%
  margin-top: 3.5rem

  .banner-content
    display: flex
    justify-content: space-evenly
    flex-direction: column
    align-items: center
    padding: 1rem

    div
      padding: 1rem

      h2
        font-size: 18px

      p
        font-size: 14px
        padding-top: 0.5rem
        font-weight: 300

    .btn
      @include mixins.btn
      font-size: 12px
      &:hover
        font-weight: 700

@include mixins.respond-to-medium
  .banner-container
    height: 25rem
    margin-top: 5rem

    .banner-content
      @include mixins.centered-view
      padding: 0

      div
        padding: 2rem 1rem

        h2
          font-size: 30px
          line-height: 2.5rem

        p
          padding-top: 1rem
          font-size: 25px

      .btn
        font-size: 18px

@include mixins.respond-to-large
  .banner-container
    height: 40rem

    .banner-content
      @include mixins.centered-view
      padding: 0

      div

        h2
          font-size: 32px

        p
          font-size: 28px
          padding-top: 2.5rem

      .btn
        font-size: 20px
