@use '../variables'
@use '../mixins'

.about-content
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 2rem 1rem

  h3
    color: variables.$text-color-secondary
    text-align: left
    font-size: 14px
    padding-right: 1rem
    font-weight: 400
    max-width: 60%
    line-height: 1.2rem

  img
    height: 9.875rem

.emphasis-container
  background-image: url('../../img/fundo3.jpg')
  // background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('../../img/fundo3.jpg')
  background-position: center
  background-size: cover
  height: 9rem
  display: flex
  justify-content: center
  align-items: center

  h3
    color: variables.$blue-logo
    text-align: center
    font-size: 14px
    font-weight: 500
    padding: 2rem

@include mixins.respond-to-medium
  .about-content
    @include mixins.centered-view
    padding: 3rem 0

    h3
      font-size: 16px
      line-height: 1.5rem

    img
      height: 12rem

  .emphasis-container
    height: 12.5rem
    background-size: contain

    h3
      @include mixins.centered-view
      padding: 3rem 0
      font-size: 16px

@include mixins.respond-to-large
  .about-content
    padding: 4rem 0

    h3
      font-size: 20px
      line-height: 2rem

    img
      height: 15rem

  .emphasis-container
    height: 15rem

    h3
      font-size: 20px
      line-height: 2rem

@include mixins.respond-to-xlarge
  .about-content
    padding: 5rem 0

    h3
      font-size: 32px
      padding-right: 3rem
      line-height: 3rem

    img
      height: 24rem

  .emphasis-container
    height: 26rem

    h3
      font-size: 30px
      line-height: 3rem
