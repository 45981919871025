@use '../variables'

#portifolio
  display: flex
  flex-wrap: wrap
  padding: 3rem
  gap: 3rem

  h1
    width: 100%
    font-size: 5rem
    margin-bottom: 4rem
    background: variables.$gradient
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
