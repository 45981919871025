@use 'variables'

*
  padding: 0
  margin: 0
  box-sizing: border-box
  font-family: variables.$font-stack
  scroll-behavior: smooth

body
  background-color: variables.$body-bg-color
  overflow-x: hidden

a, svg
  transition: 0.3s
