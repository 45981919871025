@use './variables'

@mixin center
  display: flex
  justify-content: center
  align-items: center

@mixin btn
  padding: 1rem 1.2rem
  color: variables.$text-color
  background: variables.$gradient
  text-decoration: none
  border-radius: 30px

@mixin centered-view
  width: 80vw
  margin: 0 auto
  max-width: 67.5rem

@mixin icon-card
  padding: 0.9rem
  border-radius: variables.$icon-border-radius
  background-color: variables.$bg-color
  color: variables.$text-color

@mixin info-card
  border-radius: variables.$ctt-card-radius
  background-color: #fff

// @mixin respond-to-small
//   @media screen (min-width: variables.$breakpoint-small)
//     @content

@mixin respond-to-medium
  @media (min-width: variables.$breakpoint-medium)
    @content

@mixin respond-to-large
  @media (min-width: variables.$breakpoint-large)
    @content

@mixin respond-to-xlarge
  @media (min-width: variables.$breakpoint-xlarge)
    @content
