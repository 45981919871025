@use '../variables'
@use '../mixins'

.contact-card
  background-color: variables.$bg-color
  margin: 1rem 0.875rem 2rem
  border-radius: variables.$container-border-radius
  display: flex
  flex-direction: column
  justify-content: space-evenly
  align-items: center
  height: 15rem

  .info-card
    @include mixins.info-card
    padding: 0.5rem 1rem
    color: variables.$bg-color
    display: flex
    justify-content: start
    width: 90%

    div

      h3
        font-size: 14px
        font-weight: 500
        line-height: 22px
        padding-left: 1rem

      p
        font-size: 12px
        font-weight: 500
        line-height: 22px
        padding-left: 1rem

        a
          text-decoration: none
          color: variables.$bg-color
          cursor: pointer

          &:hover
            color: variables.$blue-logo

    #email
      position: absolute
      z-index: 1

    .feedback
      background-color: variables.$blue-medium
      text-align: center
      padding: 10px
      color: white
      border-radius: 5px
      display: inline-block
      position: relative
      font-size: 10px
      top: 85%
      right: 50%
      z-index: 10
      height: fit-content

    .icon
      @include mixins.icon-card
      display: flex
      align-items: center

      a
        text-decoration: none
        color: variables.$text-color
        height: 20px

        &:hover
          transform: scale(1.2)

      svg
        font-size: 20px

  @include mixins.respond-to-medium
    @include mixins.centered-view
    margin: 2rem auto

    .info-card

      div

        h3
          font-size: 17px

        p
          font-size: 16px

      .feedback
        font-size: 12px
        right: 45%

  @include mixins.respond-to-large
    margin: 3rem auto
    height: 20rem

    .info-card
      padding: 1rem 2rem

      div

        h3
          font-size: 23px
          line-height: 28px

        p
          font-size: 20px
          line-height: 28px

      .icon
        a
          height: 25px

        svg
          font-size: 25px

      .feedback
        font-size: 14px
        right: 44%
        top: 98%

  @include mixins.respond-to-xlarge
    height: 25rem
