@use '../variables'
@use '../mixins'

.questions-container
  // background-image: url('../../img/texture.jpg')
  // background-image: url('../../img/fundo2.svg')
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(1, 106, 112, 1.3)), url('../../img/tablet.jpg')
  background-blend-mode: multiply
  background-repeat: no-repeat
  background-size: cover
  min-height: 15rem
  display: flex

  .text-container
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: center
    padding: 1rem
    flex-grow: 1

    .display-quotes
      display: flex
      align-items: center
      justify-content: center
      font-size: 16px
      font-weight: 500
      line-height: 25px
      width: 100%

      span
        font-size: 45px

      p
        padding: 0.5rem 1rem
        width: 13rem

    @include mixins.respond-to-medium
      @include mixins.centered-view
      padding: 0
      min-height: 20rem

  @include mixins.respond-to-large
    min-height: 30rem

    .text-container

      .display-quotes

        span
          font-size: 75px

        p
          padding: 1rem 2rem
          font-size: 25px
          line-height: 40px
          width: 50%

  @include mixins.respond-to-xlarge
    min-height: 52rem

    .text-container
      padding: 2.5rem 0

      .display-quotes

        span
          font-size: 80px

        p
          font-size: 32px
          line-height: 45px
