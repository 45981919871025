@use '../variables'
@use '../mixins'

header
  background-color: #fff
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 10000

  .header-container
    height: 3.5rem
    color: variables.$text-color
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0.5rem 1rem
    font-size: 14px

    .logo-container
      display: flex
      align-items: center

      img
        height: 3rem
        mix-blend-mode: multiply

    .hamb-menu

      button
        border: none
        background: transparent
        transition: transform 0.3s ease

        svg
          color: variables.$text-color-secondary
          font-size: 30px

      div
        display: none

      .menu-closed
        transform: rotate(0deg)

      .menu-open
        transform: rotate(90deg)

      .show
        display: flex
        background: variables.$bg-color
        width: 50vw
        right: 0px
        top: 3.5rem
        position: absolute
        z-index: 10
        height: 100vh
        justify-content: center

        ul
          display: flex
          flex-direction: column
          justify-content: space-evenly
          height: 20vh

          li
            list-style: none
            text-align: center

            a
              text-decoration: none
              color: variables.$text-color
              font-size: 15px
              font-weight: 500

    .nav-container
      display: none

  @include mixins.respond-to-medium
    .header-container
      @include mixins.centered-view
      padding: 0
      height: 5rem

      .logo-container

        img
          height: 4.5rem

      .hamb-menu
        display: none

      .nav-container
        display: flex
        flex-grow: 0.6
        justify-content: space-between
        color: variables.$text-color-secondary
        ul
          display: flex
          flex-direction: row
          justify-content: space-between
          flex-grow: 1

          li
            list-style: none

            a
              text-decoration: none
              font-size: 16px
              font-weight: 400
              color: variables.$text-color-secondary

  @include mixins.respond-to-large
    .header-container
      height: 5rem

      .nav-container

        ul

          li

            a
              font-size: 18px
