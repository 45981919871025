@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')

$font-stack: "Montserrat", sans-serif
$text-color: #fff
$text-color-secondary: #016A70
$bg-color: #4d979b
$body-bg-color: #fff
$bg-color-secondary: #1d1d1d
$container-border-radius: 25px
$icon-border-radius: 20px
$ctt-card-radius: 10px
$gradient: linear-gradient(100deg, #58B9E1 20%, #7AC590 80%)
$blue-logo: #58B9E1
$green-logo: #7AC590
$blue-medium: #73B5D5
$green-medium: #7BB77A

$breakpoint-small: 576px
$breakpoint-medium: 700px
$breakpoint-large: 992px
$breakpoint-xlarge: 1400px
