@use '../variables'
@use '../mixins'

.promo-container
  height: 12rem
  @include mixins.center

  .promo-content
    @include mixins.center

    h2
      font-size: 14px
      color: variables.$bg-color
      padding: 2rem
      text-align: center
      align-self: center
      position: relative
      z-index: 1

    img
      position: absolute
      z-index: -10
      height: inherit
      scale: 0.7

  @include mixins.respond-to-medium
    @include mixins.centered-view
    height: 18rem

    .promo-content

      h2
        font-size: 16px
        line-height: 25px

      img
        scale: 1

  @include mixins.respond-to-large
    height: 22rem

    .promo-content

      h2
        font-size: 20px
        line-height: 35px

      img
        height: 17rem

  @include mixins.respond-to-xlarge
    height: 30rem

    .promo-content

      h2
        font-size: 29px
        line-height: 45px

      img
        height: 22rem
