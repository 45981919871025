@use '../variables'
@use '../mixins'

.footer-container
  height: 8rem
  width: 100vw
  background-image: url('../../img/verso.jpg')
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  background-attachment: scroll

@include mixins.respond-to-medium
  .footer-container
    height: 16rem

@include mixins.respond-to-large
  .footer-container
    height: 10rem
    background-repeat: repeat
    background-size: contain
    margin-top: 6rem

@include mixins.respond-to-xlarge
  .footer-container
    height: 12rem
    background-repeat: repeat
    background-size: contain
